@import url(sanitize.css);

article ul {
  list-style: square;
}

main,
header,
footer,
article,
section,
details,
summary {
  margin: 0 auto;
  margin-bottom: 16px;
  width: 100%;
}

aside {
  margin: 0 auto;
  margin-bottom: 16px;
  margin-left: 16px;
  float: right;
}

main {
  display: block;
  margin: 0 auto;
  max-width: 768px;
  padding: 0 16px 16px;
}

footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 0;
  text-align: center;
}

footer p {
  margin-bottom: 0;
}

body > header {
  text-align: center;
}

body > header nav {
  display: inline-block;
}

body > header nav > * {
  display: inline-block;
}

header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 0;
}

header p {
  margin-top: 0;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: baseline;
}


embed,
iframe,
video {
  border: 0;
}

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;
  line-height: 1.5;
}

p {
  margin: 0;
  margin-bottom: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: inherit;
  line-height: 1.2;
  font-weight: 500;
  padding: 10px 10px;
}

h1 {
  font-size: 32.00px;
  margin: 20px 0 16px;
}

h2 {
  font-size: 28.00px;
  margin: 20px 0 16px;
}

h3 {
  font-size: 24.00px;
  margin: 16px 0 4px;
}

h4 {
  font-size: 20.00px;
  margin: 16px 0 4px;
}

h5 {
  font-size: 18.00px;
  margin: 16px 0 4px;
}

h6 {
  font-size: 16.00px;
  margin: 16px 0 4px;
}

small {
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
}

pre {
  background: #f3f3f3;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
  margin: 16px 0;
  padding: 16px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

code {
  color: rgba(0, 0, 0, 0.8);
  background: #f3f3f3;
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
  line-height: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: #101010;
  text-decoration: none;
  background-color: transparent;
}

a:hover,
a:focus {
  color: #000000;
  text-decoration: underline;
}

dl {
  margin-bottom: 16px;
}

dd {
  margin-left: 40px;
}

ul,
ol {
  margin-bottom: 8px;
  padding-left: 40px;
  vertical-align: baseline;
}

blockquote {
  border-left: 2px solid rgba(0, 0, 0, 0.8);
  font-style: italic;
  margin: 16px 0;
  padding-left: 16px;
}

figcaption {
  text-transform: uppercase;
  text-align: center;
}

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
}

sup {
  font-size: 14.00px;
  vertical-align: super;
}

sub {
  font-size: 14.00px;
  vertical-align: sub;
}

mark {
  background: #ffeb3b;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"] {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 1.5;
  -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

input[type="color"] {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

input:not([type]) {
  -webkit-appearance: none;
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 1.5;
  -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-align: left;
}

select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  background-color: #fff;
  border-color: #888888;
  outline: 0;
  -moz-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  -o-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  -ms-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
}

input:not([type]):focus {
  background-color: #fff;
  border-color: #888888;
  outline: 0;
  -moz-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  -o-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  -ms-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.25);
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: rgba(0, 0, 0, 0.12) thin;
}

select[disabled],
textarea[disabled],
input[type="text"][disabled],
input[type="password"][disabled],
input[type="email"][disabled],
input[type="url"][disabled],
input[type="date"][disabled],
input[type="month"][disabled],
input[type="time"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="week"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="color"][disabled] {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  cursor: not-allowed;
  opacity: 1;
}

input:not([type])[disabled] {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
  cursor: not-allowed;
  opacity: 1;
}

input[readonly],
select[readonly],
textarea[readonly] {
  border-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}

input:focus:invalid,
select:focus:invalid,
textarea:focus:invalid {
  border-color: #000000;
  color: #CF4243;
}

input[type="file"]:focus:invalid:focus,
input[type="radio"]:focus:invalid:focus,
input[type="checkbox"]:focus:invalid:focus {
  outline-color: #CF4243;
}

select {
  border: 1px solid rgba(0, 0, 0, 0.12);
  vertical-align: sub;
}

select:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select[multiple] {
  height: auto;
}

label {
  display: inline-block;
  line-height: 2;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 8px 0;
}

legend {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  margin-bottom: 8px;
  padding: 8px 0;
  width: 100%;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  display: inline;
}

button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  background-color: #101010;
  border: #101010;
  border-radius: 4px;
  color: #fff;
  padding: 8px 16px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -ms-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

button::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
  padding: 0;
}

button:not(:disabled):active,
input[type="submit"]:not(:disabled):active,
input[type="reset"]:not(:disabled):active,
input[type="button"]:not(:disabled):active {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}

button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}

button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  outline: 0;
  -moz-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.50);
  -o-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.50);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.50);
  -ms-box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.50);
  box-shadow: 0 0 0 0.2rem rgba(16.00, 16.00, 16.00, 0.50);
}

button:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="button"]:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  background-color: #101010;
  border-color: #101010;
  color: #fff;
}

table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
}

caption {
  padding: 8px 0;
}


thead th {
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  text-align: left;
}

tr {
  margin-bottom: 8px;
}

th,
td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
  vertical-align: inherit;
}


tfoot tr {
  text-align: left;
}

tfoot td {
  color: rgba(0, 0, 0, 0.54);
  font-size: 8px;
  font-style: italic;
  padding: 16px 4px;
}

* {
  margin: 0px;
}

header,
footer {
  padding: 0px;
  border: none;
}

body > header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  justify-content: center;
}

body > footer {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
  border: none;
  background: #f8f8f8;
}

body > header > * {
  height: 100%;
  background: #f8f8f8;
  display: grid;
  font-stretch: extra-expanded;
  letter-spacing: 0.5em;
  align-items: center;
  text-align: end;
}

a {
  display: inline-block;
}

nav a, h1 a, h2 a, h3 a, h4 a, h5 a {
  width: 100%;
  text-transform: uppercase;
}

body > header a:link, h1 a:link, h2 a:link, h3 a:link, h4 a:link, h5 a:link {
  text-decoration: none;
}

article nav {
  display: block;
}

h1,
h2,
h3,
h4,
h5 {
  background: #f8f8f8;
  padding: 10px 0px;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  color: #000;
  background: #f8f8f8;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
  margin: 0px;
  color: #000;
  background: #f8f8f8;
}

h1:hover,
h2:hover,
h3:hover,
h4:hover,
h5:hover,
a:hover {
  color: #fff;
  background: #000;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a {
  color: #fff;
  background: #000;
}

a:link {
  text-decoration: underline;
  text-decoration-style: dotted;
}

a:hover {
  text-decoration: none;
}

aside {
  background: #f8f8f8;
}

@media screen and (max-width: 480px) {
  article,
  section,
  aside {
    clear: both;
    display: block;
    max-width: 100%;
  }

  h1 {
    font-size: 24.00px;
  }

  h2 {
    font-size: 20.00px;
  }

  h3 {
    font-size: 18.00px;
  }

  h4 {
    font-size: 16.00px
  }

  h5 {
    font-size: 14.00px;
  }

  h6 {
    font-size: 12.00px;
  }

  body > header > * {
    height: 100%;
    font-stretch: expanded;
    letter-spacing: 0.2em;
    align-items: center;
    text-align: end;
  }
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin: auto 0;
}
